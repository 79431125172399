import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS 6
import { Observable, of, Subject } from 'rxjs';
import { tap, mapTo, catchError } from 'rxjs/operators';
import { config } from './../../config';
import { Tokens } from './../models/tokens';

@Injectable()
export class AuthService {

    private readonly JWT_TOKEN = 'JWT_TOKEN';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    private readonly ROLES = 'ROLES';
    private readonly USERNAME = 'USERNAME';
  
    public loggedUser: string;
    public userNameChange: Subject<string> = new Subject<string>();
    
    constructor(private http: HttpClient) {
      this.loggedUser = localStorage.getItem(this.USERNAME);
      this.userNameChange.next(this.loggedUser);  // on modifie notre Subject avec la nouvelle valeur de userName
    }
  
    authUrl = `${config.apiUrl}/auth`;

    login(user: { username: string, password: string }): Observable<boolean> {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
      };
      console.log(this.authUrl + `/signin`);
      return this.http.post<any>(this.authUrl + `/signin`, user, httpOptions)
        .pipe(
          tap(tokens => this.doLoginUser(user.username, tokens)),
          mapTo(true),
          catchError(error => {
            return of(false);
          }));
    }

    logout() {
      this.doLogoutUser()
    }
    
    isLoggedIn() {
      return !!this.getJwtToken();
    }
    
    refreshToken() {
      return this.http.post<any>(this.authUrl + `/refreshtoken`, {
        'refreshToken': this.getRefreshToken()
      }).pipe(tap((tokens: Tokens) => {
        this.storeJwtToken(tokens.accessToken);
      }));
    }
  
    getJwtToken() {
      console.log(localStorage.getItem(this.JWT_TOKEN));
      return localStorage.getItem(this.JWT_TOKEN);
    }
  
    private doLoginUser(userName: string, tokens: Tokens) {
      this.loggedUser = userName; // on met à jour loggedUser
      localStorage.setItem(this.USERNAME, userName);  // on stock le userName dans la mémoire du navigateur
      this.userNameChange.next(userName);  // on modifie notre Subject avec la nouvelle valeur de userName
      this.storeTokens(tokens);
    }
  
    private doLogoutUser() {
      this.loggedUser = null;
      localStorage.removeItem(this.USERNAME);
      this.removeTokens();
    }
  
    private getRefreshToken() {
      return localStorage.getItem(this.REFRESH_TOKEN);
    }
  
    private storeJwtToken(accessToken: string) {
      console.log("token : ");
      console.log(accessToken);
      localStorage.setItem(this.JWT_TOKEN, accessToken);
    }
  
    private storeTokens(tokens: Tokens) {
      console.log("storeTokens : ")
      console.log(tokens.accessToken)
      console.log(tokens.refreshToken)
      console.log(tokens.roles.join())
      localStorage.setItem(this.JWT_TOKEN, tokens.accessToken);
      localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
      localStorage.setItem(this.ROLES, tokens.roles.join('###'));
    }
  
    private removeTokens() {
      localStorage.removeItem(this.JWT_TOKEN);
      localStorage.removeItem(this.REFRESH_TOKEN);
      localStorage.removeItem(this.ROLES);
    }
    getRole() {
      return localStorage.getItem(this.ROLES).split('###');
    }

}
