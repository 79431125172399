<div class="header center">Log In</div>

<form [formGroup]="loginForm" (ngSubmit)="login()">

  <div class="form-group">
    <input type="text" id="username" placeholder="Username" autocomplete="off" formControlName="username" required>
  </div>

  <div class="form-group">
    <input type="password" id="password" placeholder="Password" formControlName="password" required>
  </div>
  <div [hidden]="!hasLoggedInFailed()"
        class="card-panel red accent-1 warning">
        Identifiants incorrects
  </div>

  <div class="actions">
    <button class="btn" type="submit" [disabled]="!loginForm.valid">LOG IN</button>
  </div>

</form>
