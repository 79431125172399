import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup;
	loginFailed: boolean = false;

	constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router) { }
  
	ngOnInit() {
	  this.loginForm = this.formBuilder.group({
		username: [''],
		password: ['']
	  });
	}
	hasLoggedInFailed() {
		return this.loginFailed;
	}
	get f() { return this.loginForm.controls; }
  
	login() {
		this.loginFailed = false;
	  	this.authService.login(
		  {
			username: this.f.username.value,
			password: this.f.password.value
		  }
	  	)
	  .subscribe(success => {
		if (success) {
		  this.router.navigate(['/']);
		}
		else {
		  this.loginFailed = true;
		}
	  });
	}
  
}
