import { Component } from '@angular/core';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'softball-stats-app',
  templateUrl: `./app.component.html`,
})
export class AppComponent {

  public loggedUserName = "";

  constructor(private auth: AuthService) {
    // on initialise notre loggedUsername depuis le service, puis on s'abonne aux changements de user
    this.loggedUserName = this.auth.loggedUser;
    this.auth.userNameChange.subscribe((value) => {
      this.loggedUserName = value
    });
  }

  isLoggedIn(){
    return this.auth.isLoggedIn();
  }
  logout() {
    this.auth.logout();
  }
}