<nav>
    <div class="nav-wrapper teal">
      <a href='#' class="brand-logo center">STB Softball</a>
      <ul id="nav-mobile" class="right">
        <li><p *ngIf="!isLoggedIn()" style="font-size: 20px; margin: 0; margin-right: 5px; ">Déconnecté</p></li>
        <li><p *ngIf="isLoggedIn()" style="font-size: 20px; margin: 0; margin-right: 5px">{{loggedUserName}}</p></li>
        <li><button *ngIf="!isLoggedIn()" routerLink="/login" class="btn">Connexion</button></li>
        <li><button *ngIf="isLoggedIn()" (click)='logout()' class="btn">Deconnexion</button></li>
      </ul>
    </div>
</nav>

<router-outlet></router-outlet>
