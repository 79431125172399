import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from './auth/token.interceptor';

import { AppComponent }  from './app.component';
import { AuthModule } from './auth/auth.module';
import { PageNotFoundComponent }  from './page-not-found.component';
import { HomeComponent }  from './home/home.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  imports:      [
  // attention l'ordre d'importation des modules détermine celui d'importation des routes
    BrowserModule,
    // import HttpClientModule after BrowserModule.
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    AuthModule,
    NoopAnimationsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent
  ],
  bootstrap:    [ AppComponent ],
})
export class AppModule { }
