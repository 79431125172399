import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';
import { LoginComponent } from './auth/containers/login/login.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { PageNotFoundComponent } from './page-not-found.component';
import { HomeComponent } from './home/home.component';

// routes
const appRoutes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'players', 
    	loadChildren: () => import('./players/players.module').then(m => m.PlayersModule),
		canActivate: [AuthGuard]
	},
	{ path: 'clubs', 
    	loadChildren: () => import('./clubs/clubs.module').then(m => m.ClubsModule),
		canActivate: [AuthGuard]
	},
	{ path: 'login',
	  component: LoginComponent
	},
	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule { }
